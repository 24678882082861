let assetDomainUrl = null;

export const removeAllChildren = (node) => {
    while (node.lastChild) {
        node.removeChild(node.lastChild);
    }
};

export const mergeStyleObjects = (htmlElement, styleToMerge) => {

    for (let key in styleToMerge) {
        htmlElement.style[key] = styleToMerge[key];
    }
};

export const getAssetsUrl = () => {
    if (!assetDomainUrl) {
        // load system assets url via data attribute on script tag
        const $scriptTag = document.getElementById('current-script');
        let url = $scriptTag.getAttribute('data-assets-url');
        if (!url) {
            console.error('Required attribute \'data-assets-url\' missing from script tag \'current-script\' set assets url on this tag to continue');
            return;
        }
        assetDomainUrl = url;
    }
    return assetDomainUrl;
};


