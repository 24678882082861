import $ from 'jquery';

//Configure vulnerability toggle
const vulnerabilityToggle = document.querySelector('#vulnerability-toggle');

if (vulnerabilityToggle) {
    const vulnerabilityDescriptions = document.querySelectorAll('.vulnerability-description');

    if (vulnerabilityDescriptions) {
        vulnerabilityToggle.addEventListener("change", () => {
            vulnerabilityDescriptions.forEach(vulnerabilityDescription => {
                vulnerabilityDescription.classList.toggle('u-hide');
            })
        })
    }
}

// Reload page from success / failure message
$(".back-register-interest").on("click", function () {
    location.reload();
});

// RADIO BUTTONS FUNCTIONALITY
$("[name='delivery']").on("change", function () {    
    $("[name=delivery]").each(function (index, el) {
        
        if (this.checked) {
            $(".form__label.js-requiredif-" + this.id).addClass("form__label--required");
            $(".form__input.js-requiredif-" + this.id)
                .addClass("form__input--required")
                .attr("required", "required")
                .trigger("input");
            $(".form__select.js-requiredif-" + this.id)
                .addClass("form__select--required")
                .attr("required", "required")
                .trigger("input");
        }
        else {
            $(".form__label.js-requiredif-" + this.id).removeClass("form__label--required");
            $(".form__input.js-requiredif-" + this.id)
                .removeClass("form__input--required")
                .attr("required", null)
                .trigger("input");
            $(".form__select.js-requiredif-" + this.id)
                .removeClass("form__select--required")
                .attr("required", null)
                .trigger("input");
        }
    });
});