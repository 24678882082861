import $ from "jquery";

export default function wireUpPostcodeLookup(api, postcode, address1, address2, address3, town, county, search, select, addressManual, addressWrapper) {
    $(search).on("click", function () {
        if (!$(postcode)[0].validity.valid || $(postcode).val() === "") {
            $(postcode).next('.form__error').show();
            $(postcode).addClass("form__input--error");
            return;
        }
        $(search).prop("disabled", true);
        $(search).addClass("spinner");
        const postcodeValue = $(postcode).val();
        api.postcodeSearch(postcodeValue,
            function (data) {
                $(postcode).siblings(".form__error-ajax").hide();
                $(addressWrapper).hide();
                $(select).empty();
                $(select).prop("disabled", false);
                $(select).append('<option value="" disabled selected>-- select an address from this list --</option>');
                data.forEach((data) => {
                    $(select).append('<option value="' + data.id + '">' + data.text + "</option>");
                });
                $(select).prop("required", true);
                $(select).show();
                $(addressManual).show();
                $(search).removeClass("spinner");
                $(search).prop("disabled", false);
            },
            function () {
                $(postcode).siblings(".form__error-ajax").show();
                $(postcode).removeClass("form__input--success").addClass("form__input--error");
                $(addressWrapper).hide();
                $(select).prop("disabled", true);
                $(select).hide();
                $(addressManual).show();
                $(search).removeClass("spinner");
                $(search).prop("disabled", false);
                $(search).html("Try Again");
            }
        );
    });

    $(select).on("change", function () {
        const addressId = this.value;
        api.retrieveAddress(
            addressId,
            function (data) {
                $(postcode).val(data.postalCode).trigger('change');
                $(address1).val(data.line1).trigger('change');
                $(address2).val(data.line2).trigger('change');
                $(address3).val(data.line3).trigger('change');
                $(town).val(data.city).trigger('change');
                $(county).val(data.province).trigger('change');
                $(addressManual).hide();
                $(addressWrapper).slideDown();
                $(search).html("Find Address");
            },
            function (errorMessage) {
                $(postcode).siblings(".form__error-ajax").show().html(errorMessage);
            }
        );

        $(this).prop("disabled", true);
        $(this).hide();
    });

    // Enter Address manually trigger
    $(addressManual).children('.js-enter-address-manually').on('click', function () {
        if ($(postcode)[0].validity.valid) {
            $(postcode).removeClass("form__input--error").addClass("form__input--success")
            $(postcode).siblings(".form__error-ajax").hide()
        } else {
            $(postcode).siblings(".form__error").show()
            $(postcode).addClass("form__input--error")
        }
        $(addressWrapper).slideDown();
        $(this).parent().hide();
        $(select).prop("disabled", true);
        $(select).hide();
    });
}