import PlansApi from '../apis/plans';
import populateDOB from './plans-populate-dob-fields';
import wireUpPostcodeLookup from './plans-postcode-lookup'
window.$ = window.jQuery = require("jquery");

const api = new PlansApi('/funeral-plans-api/');

populateDOB("#customer");

// FORM SUBMISSION
$("#canx-form").on("submit", function (e) {
    e.preventDefault();

    if (!this.checkValidity()) {
        // Handle hidden address fields valdiation visibility
        if ($("#canx-form .js-validate:invalid, #canx-form .js-validate-dob:invalid").first().is(":hidden") && $("#canx-form .js-validate:invalid, #canx-form .js-validate-dob:invalid").first().parents("#address-fields")) {
            $("#postcode-address-list").next(".form__error").show();
            $("#postcode-address-list").parents(".form__group").children("label")[0].scrollIntoView();
        }
        // handle scroll to in form__fields
        else if ($("#canx-form .js-validate:invalid, #canx-form .js-validate-dob:invalid").first().siblings("label").length > 0) {
            $("#canx-form .js-validate:invalid, #canx-form .js-validate-dob:invalid").first().siblings("label")[0].scrollIntoView();
        }
        // Handle scroll to in form__groups
        else if ($("#canx-form .js-validate:invalid, #canx-form .js-validate-dob:invalid").first().parents(".form__group").children("label").length > 0) {
            $("#canx-form .js-validate:invalid, #canx-form .js-validate-dob:invalid").first().parents(".form__group").children("label")[0].scrollIntoView();
        }
    } else {
        $("#canx-form [type=submit]").prop("disabled", true);
        $("#canx-form [type=submit]").addClass("submitting").text("Submitting");

        var so = {
            planId: $("#plan-number").val(),
            title: $("#title").val(),
            surname: $("#surname").val(),
            dob: $("#customer-dob-year").val() + "-" + $("#customer-dob-month").val() + "-" + $("#customer-dob-day").val(),
            address1: $("#address1").val(),
            address2: $("#address2").val(),
            address3: $("#address3").val(),
            address4: $("#town").val(),
            address5: $("#county").val(),
            postcode: $("#postcode").val(),
            contactno: $("#telephone").val(),
            cancellationReason: $("#cancellation-reason option:selected").text(),
            emailId: $("#email").val(),
            response: ""
        };

       
        api.submitCancelRequest(
            so,
            function (data) {
                if (data === "Plan Validated") {
                    $(".canx-form__content").addClass("u-hide");
                    //$(".canx-form__success").response = "canx-success";
                    $(".canx-form__success").removeClass("u-hide");
                    $("#canx-form")[0].scrollIntoView();
                }
                else {
                    
                    $(".canx-form__content").addClass("u-hide");
                    $(".canx-form__failure").removeClass("u-hide");
                    $("#canx-form")[0].scrollIntoView();
                }
            },
            function () {
                $(".canx-form__failure .form__addinfo").text("Request Failed");
                $(".canx-form__content").addClass("u-hide");
                $(".canx-form__failure").removeClass("u-hide");
                $("#canx-form")[0].scrollIntoView();
            });
    }
    return false;
});
$("#canx-form").prop("noValidate", true);

$(".js-validate, .js-validate-dob").on("invalid", function (e) {
    showInvalid(e.target);
});

var showValid = function (field) {
    $(field).removeClass("form__" + field.nodeName.toLowerCase() + "--error");
    if ($(field).val() === "") { // Don't bother showing valid on empty fields
        $(field).removeClass("form__" + field.nodeName.toLowerCase() + "--success");
    }
    else {
        $(field).addClass("form__" + field.nodeName.toLowerCase() + "--success");
    }
    if ($(field).next(".form__error").length > 0) {
        $(field).next(".form__error").hide();
    } else {
        $(field).parents(".form__group").find(".form__error").hide();
    }
}
var showInvalid = function (field) {
    $(field).removeClass("form__" + field.nodeName.toLowerCase() + "--success").addClass("form__" + field.nodeName.toLowerCase() + "--error");
    if ($(field).next(".form__error").length > 0) {
        $(field).next(".form__error").show(); // for form__fields
    } else {
        $(field).parents(".form__group").find(".form__error").show(); // For form__groups
    }
}
// Behaviour here is that if the field is not _yet_ valid, because the user may still be typing then don't show error yet,
// if the user gets to a valid state, show that, then if the user finishes editing or changes the value from a valid one to an invalid one, show the error
// During alteration of a form field (oninput = user editing) check if user has gone from valid to invalid
$(".js-validate").on("input", function () {
    if (this.validity.valid) {
        showValid(this);
    }
    else if (!this.validity.valid && $(this).hasClass("form__input--success")) {
        showInvalid(this);
    }
});
// When the user has finished entering information check validity and show state (onchange = user finished)
$(".js-validate").on("change", function () {
    if (this.validity.valid) {
        showValid(this);
    }
    else {
        showInvalid(this);
    }
});

var dobValidate = function (field) {
    var day = $(field).parents(".form__group").find(".form__select--dob-day").val();
    var month = $(field).parents(".form__group").find(".form__select--dob-month").val();
    var year = $(field).parents(".form__group").find(".form__select--dob-year").val();

    var dob = new Date(year + "-" + month + "-" + day);
    var dateNow = new Date();
    var date18 = new Date(dateNow.getFullYear() - 18, dateNow.getMonth(), dateNow.getDate());
    if (dob === undefined || dob.getFullYear() != year || (dob.getMonth() + 1) != month || dob.getDate() != day) {
        let errorMessage = "Please enter a valid date of birth";
        $(field).parents(".form__group").find(".js-validate-dob").each(function () { this.setCustomValidity(errorMessage); });
        $(field).parents(".form__group").find(".js-validate-dob").removeClass("form__select--success").addClass("form__select--error");
        $(field).parents(".form__group").find(".form__error").text(errorMessage).show();
    }
    else if (date18 < dob) {
        let errorMessage = "You must be 18 or older to cancel a plan";
        $(field).parents(".form__group").find(".js-validate-dob").each(function () { this.setCustomValidity(errorMessage); });
        $(field).parents(".form__group").find(".js-validate-dob").removeClass("form__select--success").addClass("form__select--error");
        $(field).parents(".form__group").find(".form__error").text(errorMessage).show();
    }
    else {
        $(field).parents(".form__group").find(".js-validate-dob").each(function () { this.setCustomValidity(""); });
        $(field).parents(".form__group").find(".js-validate-dob").removeClass("form__select--error").addClass("form__select--success");
        $(field).parents(".form__group").find(".form__error").hide();
    }
}

var dobInProgress = null;
$(".js-validate-dob").on("change", function () {
    dobInProgress = this;
    var readyToValidate = true;
    $(this).parents(".form__group").find(".js-validate-dob").each(function () {
        if (this.value === "") {
            readyToValidate = false;
        }
    });
    if (readyToValidate) {
        dobValidate(this);
    }
});

$(".js-validate").on("focus", function () {
    if (dobInProgress !== null && $(dobInProgress).parents(".form__group").find(this).length === 0) {
        dobValidate(dobInProgress);
        dobInProgress = null;
    }
});

$(".request-another-cancellation").on("click", function () {
    location.reload();
});

$(".return-to-form").on("click", function () {
    $(".canx-form__content").removeClass("u-hide");
    $(".canx-form__failure").addClass("u-hide");
    $("#canx-form [type=submit]").prop("disabled", false);
    $("#canx-form [type=submit]").removeClass("submitting").text("Submit Cancellation Request");
    $("#canx-form")[0].scrollIntoView();
})

wireUpPostcodeLookup(api, "#postcode", "#address1", "#address2", "#address3", "#town", "#county",
    "#postcode-lookup", "#postcode-address-list", "#address-manually", "#address-fields");