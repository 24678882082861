/* This file is responsible for lazy-js file injection */


const components = [
    { selector: ".accordion", fileName: "accordion.js" },
    { selector: ".carousel", fileName: "carousel.js" },
    { selector: ".card-grid--carousel", fileName: "carousel.js" },
    { selector: ".checkout__sidewidget", fileName: "checkoutSidewidget.js" },
    { selector: ".form__input--date", fileName: "datepicker.js" },
    { selector: ".map__object", fileName: "maps.js" },
    { selector: ".modal", fileName: "modals.js" },
    { selector: "[data-scroll-to]", fileName: "scrollTo.js" },
    { selector: ".js-print-modal", fileName: "modals.js" },
    { selector: ".js-add-email-modal", fileName: "modals.js" },
    { selector: ".js-remove-email-modal", fileName: "modals.js" },
    { selector: ".tabs", fileName: "tabs.js" },
    { selector: ".toggle", fileName: "toggle.js" },
    { selector: "[data-toggle-on-select-item]", fileName: "toggle.js" },
    { selector: "[data-toggle]", fileName: "toggle.js" },
    { selector: "#InstalmentCalculatorForm", fileName: "InstalmentCalculator.js" },
    { selector: "[data-lity]", fileName: "modals.js" },
    { selector: "[data-limit-content]", fileName: "modals.js" },
    { selector: ".comparison-table", fileName: "tables.js" },
    { selector: ".lazy-load", fileName: "lazy.js" },
    { selector: "[data-size]", fileName: "globals.js" },
    { selector: ".jumpto__title", fileName: "jumpto.js" },
    { selector: "#article-grid", fileName: "articleTemplate.js" }
];

const validators = [
    { selector: "form#BrochureForm", fileName: "brochure.js" },
    { selector: '[data-form-validator="Buy Online"], [data-form-validator="Step 1"]', fileName: "plan-selection.js" },
    { selector: '[data-form-validator="Step 2"]', fileName: "policy-details.js" },
    { selector: '[data-form-validator="Step 3"]', fileName: "payment-options.js" },
    { selector: '[data-form-validator="Step 4"]', fileName: "confirmation.js" },
    { selector: '[data-form-validator="Step 5"]', fileName: "payment-details.js" },
    { selector: 'form#ContactForm', fileName: "contact-form.js" },
    { selector: 'form#costcalculator', fileName: "cost-calculator.js" },
    { selector: 'form#EmailQuoteForm', fileName: "email-quote.js" },
    { selector: 'form#ShareYourWishesForm', fileName: "share-wishes-form.js" },
    { selector: 'form#InstalmentCalculatorForm', fileName: "instalment-calculator.js" }
]

const componentList = [
    ...components,
    ...validators
]


export default componentList;