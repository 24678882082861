export default function PlansApi(url) {
    this.url = url;
    this.postcodeSearch = function (postcode, onFinish, onError) {
        return this.sendRequest("GET", "postcode/" + postcode, null, onFinish, onError);
    };
    this.retrieveAddress = function (addressId, onFinish, onError) {
        return this.sendRequest("GET", "postcode/retrievebyid/" + addressId, null, onFinish, onError);
    };
    this.validate = function (sourcecode, onFinish, onError) {
        return this.sendRequest("GET", "PricingInfo/validate/" + sourcecode, null, onFinish, onError);
    };
    this.memberNumber = function (onFinish, onError) {
        return this.sendRequest("GET", "Plan/memberNumber/", null, onFinish, onError);
    };
    this.validateDirectDebit = function (BankDetails, onFinish, onError) {
        return this.sendRequest("POST", "DirectDebit/validate/", BankDetails, onFinish, onError);
    };
    this.submitCheckout = function (formJson, onFinish, onError) {
        return this.sendRequest("POST", "Plan", formJson, onFinish, onError);
    }
    this.worldpaySuccess = function (formJson, onFinish, onError) {
        return this.sendRequest("POST", "Plan/worldpaysuccess", formJson, onFinish, onError);
    }
    this.worldpayFailure = function (formJson, onFinish, onError) {
        return this.sendRequest("POST", "Plan/worldpayfailure", formJson, onFinish, onError);
    }
    this.worldpayCancel = function (formJson, onFinish, onError) {
        return this.sendRequest("POST", "Plan/worldpaycancel", formJson, onFinish, onError);
    }
    this.submitRequest = function (formJson, onFinish, onError) {
        return this.sendRequest("POST", "enquiry", formJson, onFinish, onError);
    }

    this.submitCancelRequest = function (formJson, onFinish, onError) {
        return this.sendRequest("POST", "FuneralPlanCancellation/CancelFuneralPlan", formJson, onFinish, onError);
    }

    this.submitRegisterInterest = function (formJson, onFinish, onError) {
        return this.sendRequest("POST", "enquiry/v2", formJson, onFinish, onError);
    }

    this.sendRequest = function (method, url, body, onFinish, onError) {
        var xhr = new XMLHttpRequest();
        xhr.open(method, this.url + url);
        if (method === "POST") {
            xhr.setRequestHeader("Content-Type", "application/json");
        }
        xhr.onload = function () {
            if (xhr.status === 200) {
                var result;
                try {
                    result = JSON.parse(xhr.responseText);
                } catch {
                    onError();
                }
                onFinish(result);
            } else {
                console.log(xhr.responseText);
                onError();
            }
        };
        if (typeof body === 'object' && body !== null) {
            body = JSON.stringify(body);
        }
        xhr.send(body);
    }
}