const body = document.body
const dropNavSections = document.querySelectorAll('.drop-nav__title')

var nav = document.querySelector(".main-nav");
var search = document.querySelector(".mobile-search");
var mobileContact = document.querySelector(".mobile-contact-details");

const menuTrigger = document.querySelector(".header__menu-trigger");

if (menuTrigger) {
    menuTrigger.addEventListener("click", function (event) {
        event.preventDefault();
        nav.classList.remove("hide");
        nav.classList.add("open");
        body.classList.add('nav-open');
    });
}

const mMenuTriger = document.querySelector(".mobile-contact-details__menu-trigger");

if (mMenuTriger) {
    mMenuTriger.addEventListener("click", function (event) {
        event.preventDefault();
        mobileContact.classList.add("open");
    });
}

const closeTrigger = document.querySelector(".header__close-trigger");

if (closeTrigger) {
    closeTrigger.addEventListener("click", function (event) {
        event.preventDefault();
        nav.classList.remove("open");
        nav.classList.add("hide");
        body.classList.remove('nav-open');
    });
}

const dropTriggers = document.querySelectorAll('.main-nav__drop-trigger')
dropTriggers.forEach(t => {

    t.addEventListener("click", function (e) {
        e.preventDefault();
        t.parentElement.closest('div').classList.toggle("open")
    });
})


document.querySelectorAll(".main-nav__search-trigger").forEach(t => {
    t.addEventListener("click", function (event) {
        event.preventDefault();

        search.classList.add("open");
        document.querySelector('.form__input').focus();
    });
});

const msClose = document.querySelector(".mobile-search__close");

if (msClose) {
    msClose.addEventListener("click", function (event) {
        event.preventDefault();

        search.classList.remove("open");
    });
}

const cdClose = document.querySelector(".mobile-contact-details__close");
if (cdClose) {
    cdClose.addEventListener("click", function (event) {
        event.preventDefault();
        mobileContact.classList.remove("open");
    });
}

if (dropNavSections) {
    dropNavSections.forEach(section => {
        section.addEventListener('click', () => {
            section.parentNode.classList.toggle('open')
        })
    })
}
const dropNav = document.querySelectorAll(".drop-nav");
const headerElement = document.querySelector('.header');

document.querySelectorAll(".main-nav__item.main-nav__item--alt")
    .forEach(nav => {
        if (nav.querySelector(".drop-nav")) {
            nav.addEventListener("mouseover", adjustDropNav);
        }
    })

function adjustDropNav(e) {
    const dropNav = e.currentTarget.querySelector(".drop-nav");
    const bannerLink = document.querySelector('#banner-link');
    let bannerLinkOffset = 0

    if(bannerLink && bannerLink.offsetParent !== null){
        bannerLinkOffset = bannerLink.offsetHeight;
    }

    dropNav.style.top = "";
    if (!headerElement.classList.contains("header--fixed")) {
        dropNav.style.top = headerElement.offsetHeight - window.pageYOffset + bannerLinkOffset + "px";
    }

}
if (headerElement) {
    window.addEventListener('scroll', () => {
        const scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
        const headerMeta = headerElement.querySelector(".header__meta");
        const headerHeight = headerElement.clientHeight;

        if (document.body.clientWidth >= 1095) {
            if (scrollTop >= headerHeight / 2) {
                headerElement.classList.add("header--fixed");
                headerMeta.classList.add("u-hide");
                document.body.style.paddingTop = headerElement.clientHeight + "px";
                headerElement.style.top = 0;
            } else {
                headerElement.classList.remove("header--fixed");
                document.body.style.paddingTop = "";
                headerMeta.classList.remove("u-hide");
                document.body.style.paddingTop = "";
                headerElement.style.top = "";
            }
        }
    });
}