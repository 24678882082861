import $ from "jquery";

export default function populateDOB(id) {

    for (let i = 1; i <= 31; i++) {
        $(id + "-dob-day").append('<option value="' + ('0' + i).slice(-2) + '">' + i + "</option>");
    }

    var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    for (let i = 0; i < monthNames.length; i++) {
        $(id + "-dob-month").append('<option value="' + ('0' + (i + 1)).slice(-2) + '">' + monthNames[i] + "</option>");
    }

    var minAge = Number($(id + "-dob-year").data("min-age"));
    if (isNaN(minAge)) {
        minAge = 18;
    }
    var currentYear = new Date().getFullYear();
    var maxYear = currentYear - minAge;
    var minYear = currentYear - 120;

    for (let i = maxYear; i >= minYear; i--) {
        $(id + "-dob-year").append('<option value="' + i + '">' + i + "</option>");
    }
}

