var loadFeefoOrganicStars = function () {
    const xhttp = new XMLHttpRequest();
    // setup callback
    xhttp.onreadystatechange = function () {
        if (this.readyState == 4 && this.status == 200) {
            const response = this.responseText;
            const data = JSON.parse(response);
            // create script element to hold linked data
            const element = document.createElement('script');
            element.type = "application/ld+json";
            // generate JSON data
            const ratingSchemaJson = `
				{
					"@context": "http:\/\/schema.org",
					"@type": "Organization",
					"name": "Simplicity Cremations",
					"url": "https://www.simplicity.co.uk/",
					"logo": "https://assets.simplicity.co.uk/assets/img/logo.svg",
                    "telephone": "0800 484 0260",
					"address": {
						"@type": "PostalAddress",
						"streetAddress": "4 King Edwards Court",
						"addressLocality": "King Edwards Square",
						"addressRegion": "Sutton Coldfield",
						"postalCode": "B73 6AP",
						"addressCountry": "GB"
					},
					"aggregateRating": {
						"@type": "AggregateRating",
                        "bestRating":"5",
                        "worstRating": 1,
						"ratingValue" : "${data.rating.rating}",
						"ratingCount": "${data.meta.count}"
					}
				}`;
            // add data to dom
            //console.log(ratingSchemaJson);
            element.innerHTML = ratingSchemaJson;
            document.getElementsByTagName("footer")[0].appendChild(element);
        }
    };
    // call feefo API and get stats
    xhttp.open("GET", "https://api.feefo.com/api/10/reviews/summary/service?merchant_identifier=simplicity-cremations", true);
    xhttp.send();
};

function initFeefoOrganicStars() {
    var validPaths = ["/", "/funeral-plans"];
    var validPage = false;
    if (Array.prototype.includes) {
        if (validPaths.includes(window.location.pathname.toLowerCase())) {
            validPage = true;
        }
    } else {
        for (var i = 0; i < validPaths.length; i++) {
            if (window.location.pathname.toLowerCase() === validPaths[i]) {
                validPage = true;
                break;
            }
        }
    }
    if (validPage) {
        window.addEventListener('DOMContentLoaded', loadFeefoOrganicStars);
    }
}
initFeefoOrganicStars();