//proto json component stuff
require('objectFitPolyfill');

import './utils/polyfills';
import 'element-closest';
import 'nodelist-foreach-polyfill';
import './components/nav';
import './vendor/feefo';
import { getAssetsUrl } from './utils/utilities';
import componentList from './_componentList';
import './components/plans-canx';
import './components/plans-populate-dob-fields';
import './components/plans-postcode-lookup';
// import './components/bannerlink';
import './components/register-your-interest';
//if (document.getElementById("canx-form")) { require('./components/plans-canx') }

function injectScriptFile(file) {
    const assetUrl = getAssetsUrl();
    if (assetUrl) {
        var fileName = [file.slice(0, file.lastIndexOf('.')), '.' + __webpack_hash__, file.slice(file.lastIndexOf('.'))].join('');
        var fileLocation = `${assetUrl}/assets/js/${fileName}`;
        var sc = document.createElement("script");
        if (!document.querySelector(`[src='${fileLocation}']`)) {
            sc.src = fileLocation;
            document.body.appendChild(sc);
        }
    }
    else {
        console.log("Unable to resolve assets URL");
    }
}

componentList.forEach(entity => {
    let invokeTargets = document.querySelectorAll(entity.selector);
    if (invokeTargets.length) {
        injectScriptFile(entity.fileName);
    }
});